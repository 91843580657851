import { fetchData } from "../utils/fetchData";
import { deleteData } from "../utils/deleteData";
import { putData } from "../utils/putData";
import { postData } from "../utils/postData";

const getFactories = async () => {
  const response = await fetchData("/factories");
  return response;
};

const getFactory = async (id) => {
  const response = await fetchData(`/factories/${id}`);
  return response;
};

const createFactory = async (data) => {
  const response = await postData("/factories", data);
  return response;
};

const updateFactory = async (id, data) => {
  const response = await putData(`/factories/${id}`, data);
  return response;
};

const deleteFactory = async (id) => {
  const response = await deleteData(`/factories/${id}`);
  return response;
};

const assignUser = async (data) => {
  const response = await postData(`/factories/assign-user/`, data);
  return response;
};

export {
  getFactories,
  getFactory,
  createFactory,
  updateFactory,
  deleteFactory,
  assignUser,
};
