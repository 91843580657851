import { fetchData } from '../utils/fetchData';
import { deleteData } from '../utils/deleteData';
import { uploadData } from '../utils/uploadData';
import { putData } from '../utils/putData';
import { postData } from '../utils/postData';

export const getAllRfqs = async (
  page = 1,
  limit = 10,
  status = '',
  search = ''
) => {
  return await fetchData(`/rfqs`, { page, limit, status, search });
};

export const getRfq = async id => {
  return await fetchData(`/rfqs/${id}`);
};

export const deleteRfq = async id => {
  return await deleteData(`/rfqs/${id}`);
};

export const getAllFactories = async () => {
  return await fetchData(`/factories`);
};

export const assignFactoryToRfq = async (id, factoryIds, expDays) => {
  return await postData(`/rfqs/${id}/assign-factory`, {
    factoryIds,
    expDays,
  });
};

export const reviseOffer = async (userId, offerData) => {
  return await putData(`/offers/revise`, { userId, ...offerData });
};

export const updateOfferNotes = async (userId, offerData) => {
  return await putData(`/offers/revise/notes`, { userId, ...offerData });
};

export const acceptOffer = async (rfqId, offerId) => {
  return await postData(`/offers/${rfqId}/accept/${offerId}`);
};

export const uploadRfqs = async (data, crType) => {
  const formData = new FormData();
  formData.append('file', data);
  formData.append('crType', crType);
  return await uploadData(`/rfqs/import`, formData);
};

export const uploadFile = async (rfqId, factoryUserId, data, userRole) => {
  return await uploadData(`/offers/${rfqId}/upload`, data);
};

export const getOfferFiles = async rfqId => {
  return await fetchData(`/offers/${rfqId}/files`);
};

export const sendToManager = async (rfqId, responsibleUserId) => {
  return await postData(`/rfqs/notify-rfq-review`, {
    rfqId,
    responsibleUserId,
  });
};

export const updateRfqNote = async (rfqId, note) => {
  return await putData(`/rfqs/${rfqId}/note`, { note });
};

export const acceptOfferForManager = async (rfqId, offerId) => {
  return await postData(`/rfqs/${rfqId}/acceptOffer/`, { rfqId, offerId });
};

export const updateSalesPrices = async (rfqId, prices) => {
  console.log('Giden veri:', prices);
  return await putData(`/rfqs/${rfqId}/update-sales-prices`, prices);
};

export const deleteRfqFile = async (rfqId, fileId) => {
  return await deleteData(`/offers/rfq-file/${rfqId}/${fileId}`);
};

export const requestRequotation = async (rfqId, factoryId) => {
  return await postData(`/rfqs/request-requotation/${rfqId}/${factoryId}`);
};
