import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  Divider,
  Chip,
} from "@mui/material";
import { useAuthUser } from "react-auth-kit";
import { useNotification } from "../../context/NotificationContext";
import Loading from "../../components/global/Loading";
import ShowError from "../../components/global/ShowError";
import { addEmail, getUserEmails } from "../../Api/Users";

const Profile = () => {
  const auth = useAuthUser();
  const user = auth();
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [additionalEmails, setAdditionalEmails] = useState(
    user?.additionalEmails || []
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Şifre kontrolü
    if (name === "newPassword" || name === "confirmPassword") {
      if (
        name === "newPassword" &&
        formData.confirmPassword &&
        value !== formData.confirmPassword
      ) {
        setPasswordError("Passwords don't match!");
      } else if (name === "confirmPassword" && value !== formData.newPassword) {
        setPasswordError("Passwords don't match!");
      } else {
        setPasswordError("");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordError) {
      showNotification("Please fix the password errors!", "error");
      return;
    }

    if (
      (formData.newPassword || formData.confirmPassword) &&
      !formData.currentPassword
    ) {
      showNotification(
        "Current password is required to change password!",
        "error"
      );
      return;
    }

    setLoading(true);
    try {
      // API call to update profile will go here
      showNotification("Profile updated successfully!", "success");
    } catch (err) {
      setError(err);
      showNotification("Failed to update profile", "error");
    } finally {
      setLoading(false);
    }
  };

  // Kullanıcının emaillerini yükle
  const loadUserEmails = async () => {
    try {
      const response = await getUserEmails(user.id);
      if (response.data && response.data.additionalEmails) {
        setAdditionalEmails(response.data.additionalEmails);
      }
    } catch (err) {
      console.error("Error loading emails:", err);
    }
  };

  useEffect(() => {
    loadUserEmails();
  }, []);

  const handleAddEmail = async () => {
    if (!newEmail) {
      showNotification("Please enter an email address", "error");
      return;
    }

    setLoading(true);
    try {
      console.log(user.id, newEmail);
      await addEmail(user.id, newEmail);
      // Email eklendikten sonra güncel listeyi yükle
      await loadUserEmails();
      setNewEmail("");
      showNotification(
        "Email added successfully. Please check your inbox for verification.",
        "success"
      );
    } catch (err) {
      showNotification(
        err.response?.data?.message || "Failed to add email",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;
  if (error) return <ShowError error={error} />;

  return (
    <Box sx={{ p: 3 }}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Avatar
                sx={{
                  width: 100,
                  height: 100,
                  bgcolor: "primary.main",
                  fontSize: "40px",
                }}
              >
                {user?.name?.charAt(0)?.toUpperCase()}
              </Avatar>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" textAlign="center">
                {user?.name}
              </Typography>
              <Typography
                variant="body1"
                textAlign="center"
                color="textSecondary"
              >
                {user?.role}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={6}>
                <Card variant="outlined" sx={{ height: "100%" }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ mb: 3 }}>
                      Profile Information
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                          Additional Emails
                        </Typography>

                        {/* Email Listesi */}
                        {additionalEmails.map((email, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                              p: 1,
                              bgcolor: "background.paper",
                              borderRadius: 1,
                            }}
                          >
                            <Typography flex={1}>{email.email}</Typography>
                            {email.isVerified ? (
                              <Chip
                                label="Verified"
                                sx={{
                                  ml: 1,
                                  bgcolor: "#98FB98", // Açık pastel yeşil
                                  color: "white", // Beyaz yazı
                                }}
                                size="small"
                              />
                            ) : (
                              <Chip
                                label="Pending Verification"
                                sx={{
                                  ml: 1,
                                  bgcolor: "#FFE4E1", // Misty Rose - Açık kırmızı
                                  color: "#D32F2F", // Koyu kırmızı yazı
                                }}
                                size="small"
                              />
                            )}
                          </Box>
                        ))}

                        {/* Yeni Email Ekleme */}
                        <Box sx={{ mt: 2 }}>
                          <TextField
                            fullWidth
                            label="Add New Email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            sx={{ mb: 1 }}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddEmail}
                            sx={{ color: "white!important" }}
                            fullWidth
                          >
                            Add Email
                          </Button>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          sx={{ color: "white!important" }}
                          fullWidth
                        >
                          Update Profile
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card variant="outlined" sx={{ height: "100%" }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ mb: 3 }}>
                      Change Password
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Current Password"
                          name="currentPassword"
                          type="password"
                          value={formData.currentPassword}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="New Password"
                          name="newPassword"
                          type="password"
                          value={formData.newPassword}
                          onChange={handleChange}
                          error={!!passwordError}
                          helperText={passwordError}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Confirm New Password"
                          name="confirmPassword"
                          type="password"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          error={!!passwordError}
                          helperText={passwordError}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          sx={{ color: "white!important" }}
                          fullWidth
                          disabled={!!passwordError}
                        >
                          Change Password
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Profile;
