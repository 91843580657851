import { Navigate, Route, Routes } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";
import NotAuth from "../containers/Errors/NotAuth";
import { SignIn } from "../containers/Auth";
import Rfq from "../containers/Rfq/Rfq";
import RfqDetail from "../containers/Rfq/RfqDetail";
import { getUserCredentials } from "../utils/getUserCredentials";
import Members from "../containers/Members/Members";
import Factories from "../containers/Factories/Factories";
import Customers from "../containers/Customers/Customers";
import FactoryUserGuide from "../containers/FactoryUserGuide/FactoryUserGuide";
import Profile from "../containers/Profile/Profile";
import EmailVerification from "../containers/EmailVerification/EmailVerification";

function AppRoutes() {
  const PrivateRoute = ({ Component, allowedRoles, path }) => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();
    const user = getUserCredentials({});

    if (!auth) {
      return <Navigate to="/login" />;
    }

    if (allowedRoles && !allowedRoles.includes(user.role)) {
      return <Navigate to="/not-auth" />;
    }

    return <Component />;
  };

  return (
    <Routes>
      <Route path="/" element={<PrivateRoute Component={Rfq} />} />
      <Route
        path="/rfqs/:id"
        element={<PrivateRoute Component={RfqDetail} />}
      />
      <Route path="/members" element={<PrivateRoute Component={Members} />} />
      <Route
        path="/factories"
        element={<PrivateRoute Component={Factories} />}
      />
      <Route
        path="/customers"
        element={<PrivateRoute Component={Customers} />}
      />
      <Route
        path="/user-guide"
        element={<PrivateRoute Component={FactoryUserGuide} />}
      />
      <Route path="/login" element={<SignIn />} />
      <Route path="/not-auth" element={<NotAuth />} />
      <Route path="/profile" element={<Profile />} />
      <Route
        path="/verify-email/:userId/:email/:token"
        element={<EmailVerification />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default AppRoutes;
