import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { verifyEmail } from "../../Api/Users";
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useIsAuthenticated } from "react-auth-kit";

const EmailVerification = () => {
  const { userId, email, token } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("verifying"); // verifying, success, error
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const verify = async () => {
      try {
        console.log("Verifying:", { userId, email, token }); // Debug için
        await verifyEmail(userId, email, token);
        setStatus("success");
      } catch (err) {
        console.error("Verification error:", err);
        setStatus("error");
      }
    };

    verify();
  }, [userId, email, token]);

  const renderContent = () => {
    switch (status) {
      case "verifying":
        return (
          <>
            <CircularProgress sx={{ mb: 2 }} />
            <Typography variant="h6">Verifying your email...</Typography>
          </>
        );

      case "success":
        return (
          <>
            <CheckCircleIcon
              sx={{
                fontSize: 60,
                mb: 2,
                color: "#98FB98", // Daha açık pastel yeşil
              }}
            />
            <Typography variant="h6" gutterBottom>
              Email Verified Successfully!
            </Typography>
            <Typography color="textSecondary" gutterBottom>
              Your email address has been successfully verified.
            </Typography>
          </>
        );

      case "error":
        return (
          <>
            <ErrorIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Verification Failed
            </Typography>
            <Typography color="textSecondary" gutterBottom>
              There was an error verifying your email address. The link might be
              expired or invalid.
            </Typography>
          </>
        );
    }
  };

  const handleNavigation = () => {
    if (isAuthenticated()) {
      navigate("/profile");
    } else {
      navigate("/login");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
      }}
    >
      <Card sx={{ maxWidth: 400, width: "100%" }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            p: 4,
          }}
        >
          {renderContent()}

          <Button
            variant="contained"
            color="primary"
            onClick={handleNavigation}
            sx={{ mt: 3, color: "white!important" }}
          >
            {isAuthenticated() ? "Go to Profile" : "Go to Login"}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default EmailVerification;
