import React from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
  Drawer,
} from "@mui/material";
import { getUserCredentials } from "../../utils/getUserCredentials";

const OfferEditDrawer = ({
  open,
  onClose,
  editingOffer,
  onValueChange,
  onSaveChanges,
}) => {
  const role = getUserCredentials().role;

  const calculateTotal = (quantity, priceDetails, isExpress = false) => {
    const unit =
      parseFloat(isExpress ? priceDetails.expUnit : priceDetails.unit) || 0;
    const tooling =
      parseFloat(isExpress ? priceDetails.expTooling : priceDetails.tooling) ||
      0;
    const etest =
      parseFloat(isExpress ? priceDetails.expEtest : priceDetails.etest) || 0;
    const quantityNum = parseFloat(quantity) || 0;

    return quantityNum * unit + tooling + etest;
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">Edit Offer Details</Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          {/* Normal fiyatlar için input alanları */}
          {Object.entries(editingOffer.values || {}).map(
            ([quantity, details]) => (
              <Grid item key={quantity} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {quantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="LT"
                      value={details.lt || ""}
                      onChange={(e) =>
                        onValueChange(quantity, "lt", e.target.value, false)
                      }
                      disabled={role !== "factoryUser" && role !== "sales"}
                      margin="dense"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Unit"
                      value={details.unit || ""}
                      onChange={(e) =>
                        onValueChange(quantity, "unit", e.target.value, false)
                      }
                      margin="dense"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Tooling"
                      value={details.tooling || ""}
                      onChange={(e) =>
                        onValueChange(
                          quantity,
                          "tooling",
                          e.target.value,
                          false
                        )
                      }
                      margin="dense"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="E-test"
                      value={details.etest || ""}
                      onChange={(e) =>
                        onValueChange(quantity, "etest", e.target.value, false)
                      }
                      margin="dense"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Total"
                      disabled
                      value={calculateTotal(quantity, details)}
                      margin="dense"
                      fullWidth
                      size="small"
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          )}

          {/* Express fiyatlar için input alanları */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Express Prices
            </Typography>
            <Divider sx={{ my: 2 }} />
          </Grid>

          {Object.entries(editingOffer.expressValues || {}).length > 0 ? (
            Object.entries(editingOffer.expressValues).map(
              ([quantity, details]) => (
                <Grid item key={`express-${quantity}`} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        {quantity} (Express)
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Exp LT"
                        value={details.expLt || ""}
                        onChange={(e) =>
                          onValueChange(quantity, "expLt", e.target.value, true)
                        }
                        disabled={role !== "factoryUser" && role !== "sales"}
                        margin="dense"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Exp Unit"
                        value={details.expUnit || ""}
                        onChange={(e) =>
                          onValueChange(
                            quantity,
                            "expUnit",
                            e.target.value,
                            true
                          )
                        }
                        margin="dense"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Exp Tooling"
                        value={details.expTooling || ""}
                        onChange={(e) =>
                          onValueChange(
                            quantity,
                            "expTooling",
                            e.target.value,
                            true
                          )
                        }
                        margin="dense"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Exp E-test"
                        value={details.expEtest || ""}
                        onChange={(e) =>
                          onValueChange(
                            quantity,
                            "expEtest",
                            e.target.value,
                            true
                          )
                        }
                        margin="dense"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Total"
                        disabled
                        value={calculateTotal(quantity, details, true)}
                        margin="dense"
                        fullWidth
                        size="small"
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            )
          ) : (
            <Typography variant="body2" color="textSecondary">
              No express prices available.
            </Typography>
          )}
        </Grid>

        <Button
          variant="contained"
          color="primary"
          onClick={onSaveChanges}
          sx={{ mt: 2, color: "white!important" }}
        >
          Re Quotation
        </Button>
      </Box>
    </Drawer>
  );
};

export default OfferEditDrawer;
