import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const RfqImportModal = ({ open, onClose, onUpload }) => {
  const [file, setFile] = useState(null);
  const [crType, setCrType] = useState('export');

  const handleFileChange = event => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Lütfen bir dosya seçin');
      return;
    }

    try {
      await onUpload(file, crType);
      setFile(null);
      setCrType('export');
      onClose();
    } catch (error) {
      alert('Dosya yüklenirken bir hata oluştu');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>RFQ Import</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>CR Type</InputLabel>
            <Select
              value={crType}
              label='CR Type'
              onChange={e => setCrType(e.target.value)}
            >
              <MenuItem value='export'>Export</MenuItem>
              <MenuItem value='domestic'>Domestic</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant='outlined'
            component='label'
            startIcon={<UploadFileIcon />}
            fullWidth
          >
            {file ? file.name : 'Dosya Seç'}
            <input
              type='file'
              hidden
              onChange={handleFileChange}
              accept='.xlsx,.xls,.csv'
            />
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={handleUpload} variant='contained' color='primary'>
          Yükle
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RfqImportModal;
