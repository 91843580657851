// api.js

import axios from 'axios';

const api = axios.create({
  baseURL: 'https://rfq-api.odakpcb.com/api/auth',
  // baseURL: 'http://localhost:8080/api/auth',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const loginUser = async userData => {
  try {
    const response = await api.post('/login', userData);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
