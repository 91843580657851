import React, { useState, useEffect } from "react";
import Loading from "../../components/global/Loading";
import {
  getMembers,
  getMember,
  createMember,
  deleteMember,
  updateMember,
  assignManager,
  removeManager,
} from "../../Api/Members";
import { DataGrid } from "@mui/x-data-grid";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Grid,
  MenuItem,
} from "@mui/material";
import EditNoteTwoTone from "@mui/icons-material/EditNoteTwoTone";
import ShowError from "../../components/global/ShowError";
import { useNotification } from "../../context/NotificationContext";

const roleOptions = [
  { value: "admin", label: "Admin" },
  { value: "manager", label: "Manager" },
  { value: "technical", label: "Technical" },
  { value: "sales", label: "Purchase" },
  { value: "cr", label: "CR" },
  { value: "factoryUser", label: "Factory User" },
];

const crTypeOptions = [
  { value: "export", label: "Export" },
  { value: "domestic", label: "Domestic" },
];

const Members = () => {
  const { showNotification } = useNotification();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [newMember, setNewMember] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
    crType: "",
    managers: [],
  });
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [memberIdToDelete, setMemberIdToDelete] = useState(null);

  const adminId = "67013281da37bebc5acc7605";

  const handleDialogOpen = (member = null) => {
    setSelectedMember(member);
    setNewMember(
      member || {
        name: "",
        email: "",
        role: "",
        password: "",
        crType: "",
        managers: [],
      }
    );
    setSelectedManagers(member?.managers || []);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedMember(null);
    setSelectedManagers([]);
  };

  const handleConfirmDialogOpen = (id) => {
    setMemberIdToDelete(id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
    setMemberIdToDelete(null);
  };

  const handleDeleteClick = async () => {
    try {
      await deleteMember(memberIdToDelete);
      showNotification("Member deleted successfully!", "success");
      fetchMembers();
    } catch (error) {
      console.log("Error deleting member:", error);
    } finally {
      handleConfirmDialogClose();
    }
  };

  const handleSaveMember = async () => {
    try {
      if (selectedMember) {
        const updateData = {
          name: newMember.name,
          email: newMember.email,
          role: newMember.role,
        };

        if (newMember.password && newMember.password.trim() !== "") {
          updateData.password = newMember.password;
        }

        if (newMember.role === "cr") {
          updateData.crType = newMember.crType;
        } else {
          delete updateData.crType;
        }

        await updateMember(selectedMember._id, updateData);

        if (newMember.role !== "factoryUser") {
          await removeManager({
            adminId,
            targetUserId: selectedMember._id,
          });

          for (const managerId of selectedManagers) {
            await assignManager({
              adminId,
              targetUserId: selectedMember._id,
              managerId,
            });
          }
        }
      } else {
        const createData = {
          name: newMember.name,
          email: newMember.email,
          password: newMember.password,
          role: newMember.role,
        };

        if (newMember.role === "cr") {
          if (!newMember.crType) {
            throw new Error("CR Type is required for CR role");
          }
          createData.crType = newMember.crType;
        }

        if (newMember.role !== "factoryUser" && selectedManagers.length > 0) {
          createData.managers = selectedManagers;
        }

        try {
          const newUser = await createMember(createData);
          console.log("User created successfully:", newUser);
        } catch (createError) {
          console.error("Error creating user:", createError);
          showNotification(
            createError.response.message || "Failed to create user",
            "error"
          );
          return;
        }
      }

      fetchMembers();
      handleDialogClose();
      showNotification(
        selectedMember
          ? "Member updated successfully!"
          : "Member created successfully!",
        "success"
      );
    } catch (error) {
      console.error("Error in handleSaveMember:", error);
      showNotification(
        error.response?.message || error.message || "Failed to save member",
        "error"
      );
    }
  };

  const fetchMembers = async () => {
    try {
      const response = await getMembers();
      setMembers(response.data);
      setManagers(response.data.filter((member) => member.role === "manager"));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  if (error) {
    return <ShowError error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ height: 700, width: "100%" }}>
      <Grid container justifyContent="flex-start">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDialogOpen()}
            sx={{ color: "white!important", my: 2 }}
          >
            Add Member
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        rows={members}
        columns={[
          { field: "name", headerName: "Name", flex: 1, editable: true },
          { field: "email", headerName: "Email", flex: 1, editable: true },
          {
            field: "role",
            headerName: "Role",
            flex: 1,
            editable: true,
            renderCell: (params) =>
              roleOptions.find((option) => option.value === params.value)
                ?.label,
          },
          {
            field: "crType",
            headerName: "CR Type",
            flex: 1,
            renderCell: (params) => {
              if (params.row.role !== "cr") return "";
              return (
                crTypeOptions.find((option) => option.value === params.value)
                  ?.label || ""
              );
            },
          },
          {
            field: "managers",
            headerName: "Managers",
            flex: 1,
            renderCell: (params) => {
              if (!params.row.managers || params.row.managers.length === 0) {
                return "";
              }
              const managerNames = params.row.managers
                .slice(0, 2)
                .map(
                  (managerId) => managers.find((m) => m._id === managerId)?.name
                )
                .filter(Boolean)
                .join(", ");

              const remaining = params.row.managers.length - 2;
              return remaining > 0
                ? `${managerNames} +${remaining}`
                : managerNames;
            },
          },
          {
            field: "actions",
            headerName: "Actions",
            renderCell: (params) => (
              <Box>
                <EditNoteTwoTone
                  style={{ cursor: "pointer", marginRight: 8 }}
                  onClick={() => handleDialogOpen(params.row)}
                />
                <DeleteTwoToneIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleConfirmDialogOpen(params.row._id)}
                />
              </Box>
            ),
          },
        ]}
        getRowId={(row) => row._id}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        disableSelectionOnClick
        autoHeight
      />

      {/* Create/Edit Member Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>
          {selectedMember ? "Edit Member" : "Add Member"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            required
            margin="normal"
            value={newMember.name}
            onChange={(e) =>
              setNewMember({ ...newMember, name: e.target.value })
            }
            error={!newMember.name}
            helperText={!newMember.name ? "Name is required" : ""}
          />
          <TextField
            label="Email"
            fullWidth
            required
            margin="normal"
            value={newMember.email}
            onChange={(e) =>
              setNewMember({ ...newMember, email: e.target.value })
            }
            error={!newMember.email}
            helperText={!newMember.email ? "Email is required" : ""}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            required
            margin="normal"
            value={newMember.password}
            onChange={(e) =>
              setNewMember({ ...newMember, password: e.target.value })
            }
            error={!newMember.password}
            helperText={!newMember.password ? "Password is required" : ""}
          />
          <TextField
            label="Role"
            select
            fullWidth
            required
            margin="normal"
            value={newMember.role}
            onChange={(e) =>
              setNewMember({ ...newMember, role: e.target.value })
            }
            error={!newMember.role}
            helperText={!newMember.role ? "Role is required" : ""}
          >
            {roleOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {newMember.role === "cr" && (
            <TextField
              label="CR Type"
              select
              fullWidth
              required
              margin="normal"
              value={newMember.crType}
              onChange={(e) =>
                setNewMember({ ...newMember, crType: e.target.value })
              }
              error={newMember.role === "cr" && !newMember.crType}
              helperText={
                newMember.role === "cr" && !newMember.crType
                  ? "CR Type is required for CR role"
                  : ""
              }
            >
              {crTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}

          {/* Set Manager Section - Only show if role is not factoryUser */}
          {newMember.role !== "factoryUser" && (
            <TextField
              label="Set Managers"
              select
              fullWidth
              margin="normal"
              value={selectedManagers}
              onChange={(e) => setSelectedManagers(e.target.value)}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => {
                  return selected
                    .map(
                      (managerId) =>
                        managers.find((m) => m._id === managerId)?.name
                    )
                    .join(", ");
                },
              }}
            >
              {managers.map((manager) => (
                <MenuItem key={manager._id} value={manager._id}>
                  {manager.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSaveMember}
            variant="contained"
            color="primary"
            sx={{ color: "white!important" }}
            disabled={
              !newMember.name ||
              !newMember.email ||
              !newMember.password ||
              !newMember.role ||
              (newMember.role === "cr" && !newMember.crType)
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Dialog */}
      <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this member?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteClick} variant="contained" color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Members;
