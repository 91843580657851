import { postData } from "../utils/postData";
import { fetchData } from "../utils/fetchData";

export const addEmail = async (userId, email) => {
  const response = await postData("/users/add-email", { userId, email });
  return response;
};

export const verifyEmail = async (userId, email, token) => {
  const response = await fetchData(
    `/users/verify-email/${userId}/${email}/${token}`
  );
  return response;
};

export const getUserEmails = async (userId) => {
  const response = await fetchData(`/users/${userId}`);
  return response;
};
